import styled from 'styled-components'


export const UpvotesDiv = styled.div`

`
export const UpvoteBtn = styled.button`
`

export const UpvoteP = styled.p`

`